import {
  ResponsiveArray,
  ResponsiveValue,
  useBreakpointValue as useBreakpointValueChakra,
} from "@biblioteksentralen/js-utils";

// Chakra's useBreakpointValue handles arrays and objects only

type ChakraBreakpointValue<T> = ResponsiveArray<T> | Partial<Record<string, T>>;

const isResponsiveArray = <T extends unknown>(value: ResponsiveValue<T>): value is ResponsiveArray<T> =>
  Array.isArray(value);

export const breakpoints = ["base", "sm", "md", "lg", "xl", "2xl"] as const;

export type Breakpoint = (typeof breakpoints)[number];

export const isBreakpoint = (item: string): item is Breakpoint => (breakpoints as readonly string[]).includes(item);

const isObjectOrArray = <T extends unknown>(value: ResponsiveValue<T>): value is ChakraBreakpointValue<T> =>
  isResponsiveArray(value) || typeof value === "object";

const useBreakpointValue = <T extends string | number | boolean | undefined>(value: ResponsiveValue<T>): T => {
  const valueIsArrayOrObject = isObjectOrArray(value);
  const chakraValue = valueIsArrayOrObject ? value : { base: undefined };
  const valueFromChakra = useBreakpointValueChakra(chakraValue);

  return valueIsArrayOrObject ? valueFromChakra! : value;
};

export default useBreakpointValue;
