import { ReactNode } from "react";
import { Global, css } from "@emotion/react";
import { ResponsiveValue } from "@biblioteksentralen/js-utils";
import ReactMasonryCSS from "react-masonry-css";

import useBreakpointValue from "../../utils/hooks/useBreakpointValue";

/*
There are some options to create masonry ourselves using css only but as far as
I could see they have drawbacks:
- column-count: gives column order instead of row order
- flex: need to set explicit height
*/

const className = "masonry-wrapper";

const columnClassName = "masonry-column";

const createGlobalStyles = (gap: string) => css`
  .${className} {
    display: flex;
    width: 100%;
  }

  .${columnClassName} {
    padding-left: ${gap};
    background-clip: padding-box;

    &:first-child {
      padding-left: 0;
    }

    /* Individual items */
    > * {
      margin-bottom: ${gap};
    }
  }
`;

type Props = {
  children: ReactNode;
  nColumns?: ResponsiveValue<number>;
  gap?: ResponsiveValue<string>;
};

const Masonry = ({ children, nColumns = 3, gap = "1rem" }: Props) => {
  const responsiveNColumns = useBreakpointValue<number>(nColumns);
  const responsiveGap = useBreakpointValue<string>(gap);

  return (
    <>
      <Global styles={createGlobalStyles(responsiveGap)} />
      <ReactMasonryCSS breakpointCols={responsiveNColumns} className={className} columnClassName={columnClassName}>
        {children}
      </ReactMasonryCSS>
    </>
  );
};

export default Masonry;
